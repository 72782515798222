'use client'

import { useRef } from 'react'

import { OneTrustScript } from '@circlefin/one-trust'
import { OneTrustProvider } from '@circlefin/one-trust/react/context/provider'
import { AnalyticsContext } from '@features/analytics.hooks/useAnalytics'
import Script from 'next/script'

import { InternalAnalyticsProvider } from './InternalAnalyticsProvider/InternalAnalyticsProvider'
import { PerformanceCookies } from './cookies'

import type { InternalAnalyticsProviderProps } from './InternalAnalyticsProvider/InternalAnalyticsProvider'
import type { ModalState } from '@features/analytics.hooks/useAnalytics'

/**
 * Props for the AnalyticsProvider component.
 */
export interface AnalyticsProviderProps {
  /**
   * Children of the provider.
   */
  children: React.ReactNode
  /**
   * Flag that indicates if analytics is enabled or not.
   */
  enabled: boolean
  /**
   * Configuration for third party scripts.
   */
  config: InternalAnalyticsProviderProps['config'] & {
    /**
     * OneTrust configuration.
     */
    oneTrust: {
      /**
       * The OneTrust token used for script initialization and consent management.
       */
      token?: string | null
    }
  }
}

/**
 * AnalyticsProvider component that sets up OneTrust and analytics tracking.
 * It wraps children with necessary context providers for analytics functionality.
 * @param props - The props for configuring the AnalyticsProvider.
 * @returns The AnalyticsProvider component.
 */
export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({
  children,
  enabled,
  config,
}) => {
  const value = useRef<ModalState | null>(null)
  return (
    <>
      {config.oneTrust.token && (
        <OneTrustScript
          component={Script}
          oneTrustToken={config.oneTrust.token}
        />
      )}
      <OneTrustProvider performanceCookies={PerformanceCookies}>
        <AnalyticsContext.Provider value={value}>
          <InternalAnalyticsProvider
            config={{
              datadog: config.datadog,
              gtm: config.gtm,
              hubspot: config.hubspot,
            }}
            enabled={enabled}
          />
          {children}
        </AnalyticsContext.Provider>
      </OneTrustProvider>
    </>
  )
}

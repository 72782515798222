import type { MutableRefObject } from 'react'
import { createContext, useCallback, useContext, useMemo } from 'react'

import { useMutation } from '@shared/apollo'

import { TrackEventDocument, TrackPageViewDocument } from '../../graphql'

import {
  extractTrackingDataFromMouseEvent,
  getPageProperties,
  getScreenProperties,
} from './utils/utils'

import type { TrackEventInput, TrackPageViewInput } from '../../graphql'
import type { ModalHistory } from '@circlefin/modal-router'

/**
 * Represents the state of a modal and its setter function.
 */
export type ModalContextState = MutableRefObject<ModalState | null>

/**
 * Analytics context used to keep track of current modal.
 */
export const AnalyticsContext = createContext<ModalContextState>({
  current: null,
})

/**
 * Represents the state of the modal router, including previous and next routes.
 */
export interface ModalState {
  /**
   * Modal that was previously displayed.
   */
  prevRoute?: ModalHistory
  /**
   * Modal that is currently displayed.
   */
  nextRoute?: ModalHistory
}

/**
 * Parameters for tracking a page view, excluding screen, page, and modal properties.
 * They will be auto-populated.
 */
export type TrackPageViewParams = Omit<
  TrackPageViewInput,
  'screen' | 'page' | 'modal'
>

/**
 * Parameters for tracking an event, excluding screen, page, and modal properties.
 * They will be auto-populated.
 */
export type TrackEventParams = Omit<
  TrackEventInput,
  'screen' | 'page' | 'modal'
>

/**
 * Response object for the useAnalytics hook.
 */
export interface UseAnalyticsResponse {
  /**
   * Set the context of the current modal.
   */
  setCurrentModal: (params: ModalState) => void
  /**
   * Track an event.
   */
  trackEvent: (params: TrackEventParams) => Promise<void>
  /**
   * Track a mouse event.
   */
  trackMouseEvent: (params: MouseEvent) => Promise<void>
  /**
   * Track a page view.
   */
  trackPageView: (params: TrackPageViewParams) => Promise<void>
}

/**
 * Custom hook for managing analytics related to modals and page views.
 * @returns An object containing functions for tracking events and managing modal state.
 */
export const useAnalytics = (): UseAnalyticsResponse => {
  const modalState = useContext(AnalyticsContext)

  const [submitPageView] = useMutation(TrackPageViewDocument, {
    onError: () => {},
  })
  const [submitTrackedEvent] = useMutation(TrackEventDocument, {
    onError: () => {},
  })

  const trackPageView = useCallback(
    async (input: Omit<TrackPageViewInput, 'screen' | 'page'>) => {
      await submitPageView({
        variables: {
          input: {
            ...input,
            page: getPageProperties(),
            screen: getScreenProperties(),
            modal: {
              nextRoute: modalState.current?.nextRoute?.pathname,
              prevRoute: modalState.current?.prevRoute?.pathname,
            },
          },
        },
      })
    },
    [modalState, submitPageView],
  )

  const trackEvent = useCallback(
    async (input: TrackEventParams) => {
      await submitTrackedEvent({
        variables: {
          input: {
            ...input,
            page: getPageProperties(),
            screen: getScreenProperties(),
            modal: {
              nextRoute: modalState.current?.nextRoute?.pathname,
              prevRoute: modalState.current?.prevRoute?.pathname,
            },
          },
        },
      })
    },
    [modalState, submitTrackedEvent],
  )

  const setCurrentModal = useCallback(
    ({ prevRoute, nextRoute }: ModalState) => {
      if (!nextRoute) {
        modalState.current = null
        return
      }

      modalState.current = {
        prevRoute,
        nextRoute,
      }

      /**
       * Track modal view as page view.
       */
      void trackPageView({
        name: `Modal - ${nextRoute.pathname}`,
        path: nextRoute.pathname,
        modal: {
          prevRoute: prevRoute?.pathname,
          nextRoute: nextRoute.pathname,
        },
      })
    },
    [modalState, trackPageView],
  )

  const trackMouseEvent = useCallback(
    async (event: MouseEvent) => {
      const events = extractTrackingDataFromMouseEvent(event)
      await Promise.all(
        events.map((evt) =>
          trackEvent({
            name: evt.event,
            properties: evt.properties,
          }),
        ),
      )
    },
    [trackEvent],
  )

  return useMemo(
    () => ({
      trackEvent,
      trackMouseEvent,
      trackPageView,
      setCurrentModal,
    }),
    [trackEvent, trackMouseEvent, trackPageView, setCurrentModal],
  )
}

import(/* webpackMode: "eager" */ "/learn-web/features/analytics/containers/AnalyticsProvider/AnalyticsProvider.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/features/common/containers/ConfigProvider/ConfigProvider.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/features/flags/containers/FeatureFlagsProvider/FeatureFlagsProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SimulatePreloadedQuery"] */ "/learn-web/node_modules/@apollo/client-react-streaming/dist/index.cc.js");
;
import(/* webpackMode: "eager" */ "/learn-web/node_modules/@circlefin/components/lib/tailwind/components.css");
;
import(/* webpackMode: "eager" */ "/learn-web/node_modules/@circlefin/fonts/circular/index.css");
;
import(/* webpackMode: "eager" */ "/learn-web/node_modules/@circlefin/tailwind/css/style.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/learn-web/node_modules/next-translate/lib/esm/AppDirI18nProvider.js");
;
import(/* webpackMode: "eager" */ "/learn-web/node_modules/next/font/local/target.css?{\"path\":\"../../packages/ui/lib/components/Global/fonts/circular/index.ts\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"variable\":\"--font-circular\",\"src\":[{\"path\":\"./Circular-Thin.woff2\",\"style\":\"normal\",\"weight\":\"200\"},{\"path\":\"./Circular-ThinItalic.woff2\",\"style\":\"italic\",\"weight\":\"200\"},{\"path\":\"./Circular-Light.woff2\",\"style\":\"normal\",\"weight\":\"300\"},{\"path\":\"./Circular-LightItalic.woff2\",\"style\":\"italic\",\"weight\":\"300\"},{\"path\":\"./Circular-Regular.woff2\",\"style\":\"normal\",\"weight\":\"400\"},{\"path\":\"./Circular-Italic.woff2\",\"style\":\"italic\",\"weight\":\"400\"},{\"path\":\"./Circular-Book.woff2\",\"style\":\"normal\",\"weight\":\"450\"},{\"path\":\"./Circular-BookItalic.woff2\",\"style\":\"italic\",\"weight\":\"450\"},{\"path\":\"./Circular-Medium.woff2\",\"style\":\"normal\",\"weight\":\"500\"},{\"path\":\"./Circular-MediumItalic.woff2\",\"style\":\"italic\",\"weight\":\"500\"},{\"path\":\"./Circular-Bold.woff2\",\"style\":\"normal\",\"weight\":\"700\"},{\"path\":\"./Circular-BoldItalic.woff2\",\"style\":\"italic\",\"weight\":\"700\"},{\"path\":\"./Circular-Black.woff2\",\"style\":\"normal\",\"weight\":\"900\"},{\"path\":\"./Circular-BlackItalic.woff2\",\"style\":\"italic\",\"weight\":\"900\"},{\"path\":\"./Circular-ExtraBlack.woff2\",\"style\":\"normal\",\"weight\":\"950\"},{\"path\":\"./Circular-ExtraBlackItalic.woff2\",\"style\":\"italic\",\"weight\":\"950\"}]}],\"variableName\":\"Circular\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ApolloProvider"] */ "/learn-web/packages/apollo/provider.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/packages/ui/style.css");

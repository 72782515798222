import type React from 'react'
import { useCallback, useEffect, useRef } from 'react'

import { useDatadogRum } from '@circlefin/datadog'
import { useModal } from '@circlefin/modal-router'
import { useFeatureFlags } from '@features/flags.hooks/useFeatureFlags'
import { ANALYTICS_IDENTIFIER_KEY } from '@shared/graphql.context/constants'
import { getCookie } from 'cookies-next'
import { usePathname } from 'next/navigation'

import type { RouteEventData } from '@circlefin/modal-router'

export const DatadogRum: React.FC = () => {
  const rumInitialized = useRef(false)
  const { setUser, startView, setGlobalContextProperty } = useDatadogRum()

  const flags = useFeatureFlags()
  const pathname = usePathname()
  const modal = useModal()

  const identifier = getCookie(ANALYTICS_IDENTIFIER_KEY)

  useEffect(() => {
    setGlobalContextProperty('featureFlags', flags)
  }, [flags, setGlobalContextProperty])

  /**
   * Track user session and update datadog session accordingly.
   */
  useEffect(() => {
    // The Session wasn't tracked yet and a session is available.
    if (!rumInitialized.current && identifier) {
      setUser({
        id: identifier,
      })

      rumInitialized.current = true
    }
  }, [identifier, setUser])

  /**
   * Track page view.
   */
  useEffect(() => {
    startView({
      name: window.location.hostname + pathname,
    })
  }, [pathname, startView])

  /**
   * Track modal view.
   */
  const trackModal = useCallback(
    (event?: RouteEventData) => {
      if (event?.nextRoute) {
        // track modal view
        return startView({
          name: `Modal: ${window.location.hostname + event.nextRoute.pathname}`,
        })
      }

      // track page view again
      startView({
        name: window.location.hostname + window.location.pathname,
      })
    },
    [startView],
  )

  /**
   * Track modal route changes.
   */
  useEffect(() => {
    modal.events.on('onRouteChange', trackModal)

    return () => modal.events.off('onRouteChange', trackModal)
  }, [modal.events, trackModal])

  return null
}
